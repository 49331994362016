
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.back_box:hover {
    color: #333;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.el-col-17 {
    padding-right: 30px;
}
.braed {
    margin: 15px 0;
}
.braed >>> .el-breadcrumb__inner.is-link:hover {
    color: #333;
    cursor: pointer;
}
.tab-rt {
    padding-left: 10px;
    margin-top: -10px;
    .relevant-anthology-wrap {
        padding: 0 18px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
        .com-title {
            div {
                img {
                    height: 29px;
                }
            }
        }
        .relevant-anthology {
            display: flex;
            justify-content: space-between;
            padding-top: 14px;
            img {
                width: 106px;
                height: 142px;
                margin-bottom: 10px;
            }
            .relevant-anthology-main {
                width: 162px;
                h5,
                p:nth-child(2) {
                    width: 162px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                h5 {
                    font-size: 16px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
                p {
                    font-size: 14px;
                    color: #666;
                    line-height: 22px;
                }
                p:nth-child(3) {
                    width: 162px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
            }
        }
        ul {
            margin-bottom: 13px;
            li {
                width: 278px;
                box-sizing: border-box;
                padding-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 36px;
                height: 36px;
                font-size: 16px;
                color: #333333;
                cursor: pointer;
                position: relative;
            }
            li::before {
                position: absolute;
                left: 0;
                top: 18px;
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #ffac13;
            }
        }
    }
    .relevant-article-wrap {
        padding: 0 18px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
        margin-top: 20px;
        .com-title {
            div {
                img {
                    height: 29px;
                }
            }
        }
        .relevant-article {
            ul {
                padding: 10px 0 14px;
                li {
                    line-height: 37px;
                    height: 37px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    position: relative;
                    font-size: 16px;
                    color: #333;
                    width: 278px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    box-sizing: border-box;
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 18px;
                }
            }
        }
    }
}
.contribut {
    padding-top: 4px;
    vertical-align: middle;
}
.contribut > div {
    width: 90px;
    height: 74px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    background: #bd1a2d;
    padding-top: 14px;
}
.contribut > img {
    width: 246px;
    height: 74px;
    vertical-align: middle;
}
.guss h5 {
    position: relative;
    height: 46px;
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: bold;
    border-bottom: 4px solid #bd1a2d;
    color: #bd1a2d;
}
.guss h5 span {
    position: absolute;
    top: -6px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    width: 176px;
    border-bottom: 4px solid #bd1a2d;
    border-bottom-right-radius: 3px;
}
.guss ul {
    padding: 10px;
    background: #f7f7f7;
}
.guss ul li {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.guss ul li:before {
    content: '';
    float: left;
    margin-top: 8px;
    margin-right: 14px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #b5b5b5;
    border-radius: 50%;
}
.guss ul li a {
    color: #292929;
}
.iedolImg {
    margin-bottom: 30px;
    width: 340px;
}
.iedolImg > img {
    width: 120px;
    height: 160px;
    display: inline-block;
    background: #ebf0f4;
    cursor: pointer;
}
.iedolMsg {
    width: 200px;
    padding: 4px 0 6px 20px;
}
.iedolMsg .itle {
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 6px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
}
.iedolMsg .author {
    margin-bottom: 8px;
}
.iedolMsg .author span {
    margin-right: 4px;
}
.iedolMsg .abs {
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
.iedolNum a {
    display: inline-block;
    width: 100px;
    line-height: 32px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    margin: 0 10px 10px 0;
}
.iedolNum a:hover,
.iedolNum a.active {
    color: #fff;
    border-color: #bd1a2d;
    background: #bd1a2d;
}

.ltCont {
    margin-top: 10px;
}
.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
    line-height: 18px;
    img {
        width: 20px;
        height: 18px;
        vertical-align: bottom;
    }
}
.pdate {
    // overflow: hidden;
}
.pdate > div {
    margin: 10px 12px 12px 0;
    width: 300px;
}
.pdate {
    .com-img {
        width: auto;
        position: relative;
        margin-right: 24px;
        img {
            width: 24px;
            height: 24px;
            margin-top: -4px;
            // margin-bottom: 20px;
            cursor: pointer;
            vertical-align: middle;
        }

        .com-img-wrap {
            position: absolute;
            left: 50%;
            top: 32px;
            transform: translateX(-50%);
            width: 80px;
            box-sizing: border-box;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            border-radius: 4px;
            display: none;
            border: 1px solid #eee;
            cursor: pointer;
            color: #333;
        }
        .code-img-wrap5 {
            width: 124px;
            height: 124px;
            text-align: center;
            line-height: 90px;
            background: #fff;
            padding-left: 6px;
            padding-top: 6px;
            div {
                width: 110px;
                height: 110px;
                overflow: hidden;
            }
        }
        .com-img-wrap::before {
            box-sizing: content-box;
            width: 0px;
            height: 0px;
            position: absolute;
            top: -16px;
            // right: 41px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;
            border-bottom: 8px solid #ffffff;
            border-top: 8px solid transparent;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            display: block;
            content: '';
            z-index: 12;
        }
        .com-img-wrap::after {
            box-sizing: content-box;
            width: 0px;
            height: 0px;
            position: absolute;
            top: -18px;
            // right: 40px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;
            border-bottom: 9px solid #cccccc;
            border-top: 9px solid transparent;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            display: block;
            content: '';
            z-index: 10;
        }
        // div::before {
        //     content: '';
        //     display: block;
        //     width: 0;
        //     height: 0;
        //     border-left: 5px solid transparent;
        //     border-right: 5px solid transparent;
        //     border-bottom: 10px solid red;
        //     position: absolute;
        //     top: -9px;
        //     left: 50%;
        //     transform: translateX(-50%);

        // }
    }
    .com-img:hover .com-img-wrap {
        display: block;
    }
    .com-img:first-child {
        margin-left: 9px;
        // div {
        //     background: red;
        // }
    }
}
.pdate span {
    margin-right: 8px;
}
.infoCont {
    padding: 20px 0;
    border-top: 1px solid #e6e6e6;
}
.infoCont >>> p {
    line-height: 22px;
    /* text-indent: 2em; */
}
.infoCont >>> img {
    max-width: 800px;
    margin: 0 auto;
    height: auto;
}
.infoCont .download-zip {
    cursor: pointer;
}
.pdate .bdsharebuttonbox {
    width: 100px;
    margin: 0;
}
.bdsharebuttonbox .bds_sqq.bds_sqq1 {
    background-image: url('../assets/img/qq.png');
    background-size: 100% 100%;
    background-position: 0 0;
}
.bdsharebuttonbox .bds_weixin.bds_weixin1 {
    background-image: url('../assets/img/wechat.png');
    background-size: 100% 100%;
    background-position: 0 0;
}
.wechat_box {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -170px;
    padding: 10px;
    width: 280px !important;
    height: 340px !important;
    background: #fff;
    border: solid 1px #d8d8d8;
    z-index: 11002;
    font-size: 12px;
}
.wechat_box > h5 {
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    line-height: 16px;
    height: 16px;
    position: relative;
    color: #000;
}
.wechat_box > h5 > a {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 0;
    color: #999;
    text-decoration: none;
    font-size: 16px;
}
.codeImg {
    // margin: 20px auto;
    // padding: 15px 10px;
    height: 200px;
    width: 200px;
}
.wechat_box > p {
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    color: #666;
}
.showPdf {
    position: relative;
    border: 2px solid #eee;
    background: #3f3f3f;
}
.showPdf .down {
    position: absolute;
    top: 9px;
    right: 130px;
}
.showPdf img {
    width: 15px;
}
.copy {
    width: 80px;
    text-align: center;
    border: 1px solid #bd1a2d;
    padding: 6px;
    background: #fff;
    font-weight: bold;
    font-size: 12px;
    color: #bd1a2d;
    cursor: pointer;
}
